// font family
$main-font: "Inter", sans-serif;
$second-font:  "Public Sans", sans-serif;
$Jost: "Jost", sans-serif;

// $second-font:"Helvetica", sans-serif;

// bg
$main-bg:#EEF2E6;
$card-bg:rgba(247,247,247,0.1);
$section-bg: rgba(17, 17, 17, 1);
// text

// transition
$transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

$white: #FFFFFF;
$black:#12141D;
$accent:rgba(61, 131, 97, 1);
$second:rgba(28, 103, 88, 1);
$error:#ff3636;

// modal
$modal-bgd: #2C2C2C;



