// ========================= Header start =========
.header{
   padding: 16px 15px;
   background-color: rgb(247,249,242);
   @include tablet{
    padding: 20px 15px;
   }

    &-nav{
        .btn{
            margin-left: 30px;

            &.hidden{
                @include tablet{
                    display: none;
                }
                @include desktop{
                    display: flex;
                }
            }
        }
    }

    
    .burger-btn {
            background: transparent;
            padding: 11px 3px;
            color: $accent;
            margin: 0;
            border: none;
            cursor: pointer;
            transition: $transition;
    
            @include desktop {
                display: none;
            }
    
            & svg {
                stroke: currentColor;
            }
    
            &:hover,
            &:focus {
                color: $second;
            }
    
            &:active {
                color: $white;
            }
        }

}


// ========================= Header end =========