// ========================================= common start
html,
body {
  font-family: $main-font;
  background-color: $main-bg;

  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: $black;
  
  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  overflow-x: hidden;
}
body.hidden {
  overflow: hidden;
}


// ------------------------------ navigation 

.navigation{
  display:  flex;
  align-items: center;
  justify-content: space-between;
}

// ------------------------------  logo 
.logo{
  width: 100%;
  max-width: 160px;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 700;
  color: inherit;
  transition: $transition;

  // display: flex;
  // gap: 12px;

  &:hover, &:focus {
      color: $accent;
    }
  
  &:active {
    color: inherit;
  }

  @include tablet{
    max-width: max-content;
  }
}


// ------------------------------ links 

.link{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 15px 15px 15px 15px;

  font-size: inherit;
  font-weight: 500;
  color: inherit;

  transition: $transition;
  border-bottom: 1px solid transparent;
  
  &:hover,&:focus{
    color: $accent;
    border-bottom: 1px solid $accent;
  }
  &:active{
    color: inherit;
    border-bottom: 1px solid $white;
  }
}


// --------------------------------- btn
.btn {
  width: 100%;
  max-width: max-content;
  padding: 14px 44px;
  background-color: $accent;
  color: $white;
  font-size: inherit;
  line-height: inherit;
  font-weight: 600;
  text-align: center;
  border-radius: 3px;
  border: 1px solid $accent;
  cursor: pointer;
}

// ------------------------------ btn hover focus active 
.hfa {
  transition: $transition;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.0);

  &:hover,
  &:focus {
    border: 1px solid $second;
    background-color: $second;
    
  }

  &:active {
    background-color: $accent;
    -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
      box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  }


}

// ------------------------------ headings 1  2  3

.heading1{
  font-family: $second-font;
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  word-break: break-word;


}
.heading2 {
  font-family: $second-font;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.2;
}
.heading3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
}
.heading4{
  font-family: $second-font;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1px;
  @include desktop{
    font-size: 42px;
  }
}

.heading5 {

  font-family: $second-font;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
}

// ------------------------------ headings 1  2  3

.content-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}



.disc{
  list-style: disc;
  padding-left: 16px;
}

.decimal{
  list-style: decimal;
  padding-left: 16px;
}



//margin

.m-0{
  margin: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-16{
  margin-bottom: 16px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-24{
  margin-bottom: 24px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.bold{
  font-weight: 700;
}

//  ------------------------------  for form validate

.just-validate-error-field.form-input{
  border:1px solid $error
}

.just-validate-error-field ~ span {
  color: $error;
}

.just-validate-error-label{

  display: none;
}



//  ------------------------------  mobile/tablet/desktop - hidden 
.header-nav,.upper, img {
  &.mobile-hidden{
    display: none;
    @include tablet{
      display: flex;
    }
  }
}
br, .header-nav,.upper, img{
  &.tablet-hidden {
    @include tablet{
      display: none;
    }
  }
}
.header-nav.tablet-hidden,.upper.tablet-hidden {
  @include desktop {
    display: flex;
  }
}
img, .btn-burger{
  &.desktop-hidden {

      @include desktop {
        display: none;
      }
    }
}

// _______________________________ submit page start
.submit{
  height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(247, 249, 242);
    & main{
      align-self: center;
      text-align: center;
    }

    & .btn{
      margin: 0 auto;
    }

    & .link{
      margin: 0 auto;
    }

}

    // _______________________________ submit page end

// ========================================= common end





