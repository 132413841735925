// =========================== privacy & terms start 
.privacy{
    padding-top: 50px;
    padding-bottom: 150px;
    color: $black;
    font-size: 18px;
    font-weight: 500;
        background-color: rgb(247, 249, 242);

        @include desktop{
            padding-top: 150px; 
        }
       
    .title{
        font-weight: 700;
            font-size: 40px;

    }
    .sub-title{
        font-weight: 700;
    }
    .lower-title{
        font-weight: 800;
            font-size: 32px;
    }
    .sub-lower-title{
        font-weight: 500;
            font-size: 18px;
    }

    span{
        display: inline-block;
    }

    &-wrapper{
            max-width: 542px;
            margin: 0 auto;
    }
}


// =========================== privacy & terms end 