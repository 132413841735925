.footer{
    padding-top: 0;
        padding-bottom: 70px;
        color: $white;
        background-color: rgba(17, 17, 17, 1);
        @include desktop{
            padding-bottom: 60px;
        }

        .upper{
            display: flex;
            flex-direction: column;

            gap: 30px;
            @include tablet{
                padding-top: 25px;
                padding-bottom: 50px;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                border-bottom: 1px solid rgba(155, 155, 155, 0.72);

            }

            .title{
                max-width: 650px;
                font-weight: 700;
                    font-size: 32px;
                    color: rgba(255, 255, 255, 0.9);

            }
        }

        .lower{
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 50px;
            @include tablet{
                padding-top: 25px;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                gap: 100px;
            } 

            .list{
                display: flex;
                flex-direction: row;
            }
        }
       
}
    

