@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 390px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    padding: 0 70px;
    max-width: 1440px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  margin-bottom: 0 !important;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Inter", sans-serif;
  background-color: #EEF2E6;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #12141D;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body.hidden {
  overflow: hidden;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 100%;
  max-width: 160px;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 700;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.logo:hover, .logo:focus {
  color: rgb(61, 131, 97);
}
.logo:active {
  color: inherit;
}
@media screen and (min-width: 768px) {
  .logo {
    max-width: max-content;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 15px 15px 15px 15px;
  font-size: inherit;
  font-weight: 500;
  color: inherit;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
}
.link:hover, .link:focus {
  color: rgb(61, 131, 97);
  border-bottom: 1px solid rgb(61, 131, 97);
}
.link:active {
  color: inherit;
  border-bottom: 1px solid #FFFFFF;
}

.btn {
  width: 100%;
  max-width: max-content;
  padding: 14px 44px;
  background-color: rgb(61, 131, 97);
  color: #FFFFFF;
  font-size: inherit;
  line-height: inherit;
  font-weight: 600;
  text-align: center;
  border-radius: 3px;
  border: 1px solid rgb(61, 131, 97);
  cursor: pointer;
}

.hfa {
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0);
}
.hfa:hover, .hfa:focus {
  border: 1px solid rgb(28, 103, 88);
  background-color: rgb(28, 103, 88);
}
.hfa:active {
  background-color: rgb(61, 131, 97);
  -webkit-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
  box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.41);
}

.heading1 {
  font-family: "Public Sans", sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  word-break: break-word;
}

.heading2 {
  font-family: "Public Sans", sans-serif;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.2;
}

.heading3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
}

.heading4 {
  font-family: "Public Sans", sans-serif;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -1px;
}
@media screen and (min-width: 1440px) {
  .heading4 {
    font-size: 42px;
  }
}

.heading5 {
  font-family: "Public Sans", sans-serif;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.disc {
  list-style: disc;
  padding-left: 16px;
}

.decimal {
  list-style: decimal;
  padding-left: 16px;
}

.m-0 {
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.bold {
  font-weight: 700;
}

.just-validate-error-field.form-input {
  border: 1px solid #ff3636;
}

.just-validate-error-field ~ span {
  color: #ff3636;
}

.just-validate-error-label {
  display: none;
}

.header-nav.mobile-hidden, .upper.mobile-hidden, img.mobile-hidden {
  display: none;
}
@media screen and (min-width: 768px) {
  .header-nav.mobile-hidden, .upper.mobile-hidden, img.mobile-hidden {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  br.tablet-hidden, .header-nav.tablet-hidden, .upper.tablet-hidden, img.tablet-hidden {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .header-nav.tablet-hidden, .upper.tablet-hidden {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  img.desktop-hidden, .btn-burger.desktop-hidden {
    display: none;
  }
}

.submit {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(247, 249, 242);
}
.submit main {
  align-self: center;
  text-align: center;
}
.submit .btn {
  margin: 0 auto;
}
.submit .link {
  margin: 0 auto;
}

.header {
  padding: 16px 15px;
  background-color: rgb(247, 249, 242);
}
@media screen and (min-width: 768px) {
  .header {
    padding: 20px 15px;
  }
}
.header-nav .btn {
  margin-left: 30px;
}
@media screen and (min-width: 768px) {
  .header-nav .btn.hidden {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .header-nav .btn.hidden {
    display: flex;
  }
}
.header .burger-btn {
  background: transparent;
  padding: 11px 3px;
  color: rgb(61, 131, 97);
  margin: 0;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
@media screen and (min-width: 1440px) {
  .header .burger-btn {
    display: none;
  }
}
.header .burger-btn svg {
  stroke: currentColor;
}
.header .burger-btn:hover, .header .burger-btn:focus {
  color: rgb(28, 103, 88);
}
.header .burger-btn:active {
  color: #FFFFFF;
}

.footer {
  padding-top: 0;
  padding-bottom: 70px;
  color: #FFFFFF;
  background-color: rgb(17, 17, 17);
}
@media screen and (min-width: 1440px) {
  .footer {
    padding-bottom: 60px;
  }
}
.footer .upper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media screen and (min-width: 768px) {
  .footer .upper {
    padding-top: 25px;
    padding-bottom: 50px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid rgba(155, 155, 155, 0.72);
  }
}
.footer .upper .title {
  max-width: 650px;
  font-weight: 700;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.9);
}
.footer .lower {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media screen and (min-width: 768px) {
  .footer .lower {
    padding-top: 25px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 100px;
  }
}
.footer .lower .list {
  display: flex;
  flex-direction: row;
}

/* backdrop при відкривтому модальному вікні */
/*  backdrop що огортає враппер модалки */
/*  показати backdrop що огортає враппер модалки */
/* @media all and (max-width:767px){
    .hystmodal__close{
        top:10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    }
    .hystmodal__window{
        margin: 0;
    }
} */
/* fix html, body */
.hystmodal__opened {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

/* modal backdrop */
.hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}

/*  backdrop що огортає враппер модалки */
.hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 99;
  visibility: hidden;
}

/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
  pointer-events: auto;
  opacity: 1;
}
.hystmodal--active .hystmodal__window {
  opacity: 1;
}

.hystmodal--moved {
  pointer-events: auto;
  opacity: 1;
}

.hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.hystmodal__window {
  position: relative;
  margin: 50px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 600px;
  max-width: 100%;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.hystmodal__close {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  font-size: 0;
  cursor: pointer;
  outline: none;
  transition: color 300ms ease-in-out;
}
.hystmodal__close svg {
  fill: currentColor;
}
.hystmodal__close:hover {
  color: rgb(61, 131, 97);
}
.hystmodal__close:focus {
  color: rgb(61, 131, 97);
  outline: 2px dotted #afb3b9;
  outline-offset: 2px;
}
.hystmodal__close:active {
  color: inherit;
}

/* ------------ pop-up */
/* ------------ burger */
.hystmodal.hystmodal--burger .hystmodal__wrap {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .hystmodal__window {
  margin: 0;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  padding-top: 32px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #12141D;
}
.hystmodal.hystmodal--burger .hystmodal__window .container {
  height: 100%;
}
.hystmodal.hystmodal--burger .hystmodal__close {
  color: #FFFFFF;
}
.hystmodal.hystmodal--burger .navigation.burger {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}
.hystmodal.hystmodal--burger .navigation.burger .header-nav {
  gap: 30px;
  width: 100%;
  height: 100%;
  padding-top: 40px;
}
.hystmodal.hystmodal--burger .navigation.burger .header-nav .list-link__item:last-child {
  margin-top: auto;
}
.hystmodal.hystmodal--burger address {
  margin-top: 200px;
}
.hystmodal.hystmodal--burger .header-address {
  align-items: flex-start;
  flex-direction: column;
}
.hystmodal.hystmodal--burger a.header-address-link {
  color: #12141D;
  max-width: 100%;
}

.hero {
  padding: 60px 0px 100px 0px;
  background-color: rgb(247, 249, 242);
}
.hero .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 1440px) {
  .hero .content-wrapper {
    flex-direction: row;
  }
}
.hero .text-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}
.hero .text {
  max-width: 410px;
}
.hero .image {
  display: none;
}
@media screen and (min-width: 768px) {
  .hero .image {
    display: block;
    width: 100%;
    max-width: 500px;
    align-self: flex-end;
  }
}

.advantages {
  color: #FFFFFF;
  padding-top: 130px;
  padding-bottom: 130px;
  background-color: rgb(28, 103, 88);
}
@media screen and (min-width: 768px) {
  .advantages {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.advantages .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
@media screen and (min-width: 1440px) {
  .advantages .list {
    flex-direction: row;
  }
}
.advantages .list__item {
  width: 100%;
  max-width: 140px;
}
@media screen and (min-width: 768px) {
  .advantages .list__item {
    max-width: 300px;
    text-align: center;
  }
}
.advantages p {
  font-weight: 500;
  color: #CBD5E1;
}

.features {
  background-color: rgb(17, 17, 17);
  color: rgba(255, 255, 255, 0.9);
  padding-top: 100px;
  padding-bottom: 100px;
}
.features h2 {
  max-width: 630px;
}
@media screen and (min-width: 768px) {
  .features h2 {
    margin: 0 auto;
    text-align: center;
  }
}
.features .list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
  margin-top: 100px;
}
@media screen and (min-width: 768px) {
  .features .list {
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 1440px) {
  .features .list {
    margin-top: 50px;
    flex-direction: row;
    gap: 110px;
  }
}
.features .list__item {
  max-width: 360px;
}
.features h3 {
  margin-bottom: 10px;
}
.features .text-container {
  padding: 25px 45px 10px 45px;
}
.features .image {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.business {
  padding-top: 100px;
  padding-bottom: 100px;
  color: #FFFFFF;
  background-color: rgb(17, 17, 17);
}
.business .list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
}
@media screen and (min-width: 1440px) {
  .business .list {
    gap: 200px;
  }
}
.business .list__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}
@media screen and (min-width: 1440px) {
  .business .list__item {
    flex-direction: row;
    gap: 170px;
    align-items: center;
  }
}
@media screen and (min-width: 1440px) {
  .business .list__item:nth-child(even) {
    flex-direction: row-reverse;
  }
}
.business .list__item:nth-child(even) .classic-text {
  margin-top: 20px;
  margin-bottom: 40px;
}
.business .list__item .image {
  width: 100%;
  max-width: 330px;
}
@media screen and (min-width: 768px) {
  .business .list__item .image {
    max-width: 600px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1440px) {
  .business .list__item .image {
    margin: 0 0 0 30px;
  }
}
.business .list__item p {
  color: rgba(255, 255, 255, 0.9);
}
.business .list__item .text {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
}

.consulting {
  color: #FFFFFF;
  padding: 100px 0;
  background-color: rgb(17, 17, 17);
}
.consulting .sup-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 156%;
  color: rgba(255, 255, 255, 0.9);
}
@media screen and (min-width: 768px) {
  .consulting .sup-title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
.consulting .title {
  margin-top: 20px;
  margin-bottom: 100px;
  max-width: 600px;
}
@media screen and (min-width: 768px) {
  .consulting .title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}
.consulting .list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
@media screen and (min-width: 1440px) {
  .consulting .list {
    flex-direction: row;
    align-items: stretch;
  }
}
.consulting .list__item {
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  border-radius: 10px;
  background-color: rgba(247, 247, 247, 0.1);
  width: 100%;
  max-width: 500px;
  min-height: 388px;
}
@media screen and (min-width: 1440px) {
  .consulting .list__item {
    max-width: 400px;
  }
}
.consulting .list__item .stars-wrapper {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 20px;
}
.consulting .list__item .author-wrapper {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 15px;
}
.consulting .list__item .author-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 162%;
  color: rgba(255, 255, 255, 0.9);
}
.consulting .list__item .author-descr {
  font-size: 14px;
  line-height: 171%;
}
.consulting .link-more {
  display: none;
}
@media screen and (min-width: 1440px) {
  .consulting .link-more {
    display: block;
    margin-top: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 162%;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    padding-bottom: 5px;
    text-decoration: underline;
  }
}

.discover {
  color: #FFFFFF;
  background-color: rgb(28, 103, 88);
  padding-top: 100px;
  padding-bottom: 100px;
}
.discover .content-wrapper {
  width: 100%;
  max-width: 650px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}
.discover .sub-title {
  margin-top: 20px;
  margin-bottom: 50px;
}
.discover .text-container {
  display: flex;
  justify-content: center;
}
.discover .text-container .wrapper {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  max-width: 224px;
  font-size: 16px;
  line-height: 162%;
  text-align: center;
}
.discover .text-container .wrapper:nth-child(1) {
  position: relative;
}
.discover .text-container .wrapper:nth-child(1)::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 46px;
  background-color: rgba(212, 212, 216, 0.3);
}
@media screen and (min-width: 1440px) {
  .discover .text-container .wrapper {
    max-width: 400px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
.discover .text-container .wrapper-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 128%;
  color: #fafafa;
  margin-bottom: 20px;
}
.discover .text-container .wrapper__sub-title {
  font-weight: 500;
  font-size: 21px;
  line-height: 148%;
  color: #fff;
  margin-bottom: 10px;
}

.strides {
  padding-top: 150px;
  padding-bottom: 150px;
  color: #FFFFFF;
  background-color: rgb(17, 17, 17);
}
.strides .title {
  font-family: "Public Sans", sans-serif;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
}
.strides .sub-title {
  margin-bottom: 40px;
}
.strides .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
@media screen and (min-width: 1440px) {
  .strides .content-wrapper {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 90px;
  }
}
.strides .image {
  width: 100%;
  max-width: 575px;
}
@media screen and (min-width: 768px) {
  .strides .image {
    align-self: center;
  }
}
@media screen and (min-width: 1440px) {
  .strides .image {
    margin-top: 50px;
  }
}
.strides .list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.strides .list__item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.blog {
  padding-top: 0;
  padding-bottom: 100px;
  color: #FFFFFF;
  background-color: rgb(17, 17, 17);
}
@media screen and (min-width: 1440px) {
  .blog {
    padding-top: 100px;
  }
}
.blog .text-wrapper {
  text-align: center;
  max-width: 630px;
  margin: 0 auto 50px auto;
}
.blog .title {
  font-weight: 700;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
}
.blog .sub-title {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
}
.blog .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 0 auto;
}
@media screen and (min-width: 1440px) {
  .blog .list {
    flex-direction: row;
  }
}
.blog .list__item {
  width: 100%;
  max-width: 635px;
}
@media screen and (min-width: 1440px) {
  .blog .list__item.second {
    max-width: 525px;
  }
}
.blog .list__item .image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}
.blog .list__item .text {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 171%;
  color: rgba(255, 255, 255, 0.9);
}
.blog .list__item .item-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 142%;
  color: rgba(255, 255, 255, 0.9);
}
.blog .list__item .text-container {
  max-width: 420px;
}

.contacts {
  padding-top: 0;
  padding-bottom: 150px;
  color: #FFFFFF;
  background-color: rgb(17, 17, 17);
}
@media screen and (min-width: 1440px) {
  .contacts {
    padding-top: 100px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  padding: 42px;
  border-radius: 10px;
  box-shadow: 0 0 10px 1px rgba(155, 155, 155, 0.3);
  background: rgba(247, 247, 247, 0.1);
}
.form-label {
  font-size: 14px;
  line-height: 171%;
  color: rgba(255, 255, 255, 0.9);
  width: 100%;
}
.form-input {
  width: 100%;
  padding: 13px;
  margin-top: 8px;
  background: #1C1C1C;
  border: 1px solid rgba(255, 255, 255, 0.9);
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.9);
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  outline: none;
}
.form-input:hover, .form-input:focus {
  border: 1px solid rgb(61, 131, 97);
}
.form-input::placeholder {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  line-height: 1.14;
  font-weight: 400;
}
.form-input.textarea {
  resize: none;
  min-height: 100px;
  border-radius: 8px;
}
.form .btn {
  margin-top: 8px;
  padding: 17px 72px;
  max-width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  border-radius: 8px;
}

.privacy {
  padding-top: 50px;
  padding-bottom: 150px;
  color: #12141D;
  font-size: 18px;
  font-weight: 500;
  background-color: rgb(247, 249, 242);
}
@media screen and (min-width: 1440px) {
  .privacy {
    padding-top: 150px;
  }
}
.privacy .title {
  font-weight: 700;
  font-size: 40px;
}
.privacy .sub-title {
  font-weight: 700;
}
.privacy .lower-title {
  font-weight: 800;
  font-size: 32px;
}
.privacy .sub-lower-title {
  font-weight: 500;
  font-size: 18px;
}
.privacy span {
  display: inline-block;
}
.privacy-wrapper {
  max-width: 542px;
  margin: 0 auto;
}