.form{
    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 100%;
    max-width: 550px;
    margin: 0 auto;

    padding: 42px;
    border-radius: 10px;
    box-shadow: 0 0 10px 1px rgba(155, 155, 155, 0.3);
    background: rgba(247, 247, 247, 0.1);

    &-label{
        font-size: 14px;
            line-height: 171%;
            color: rgba(255, 255, 255, 0.9);
        width: 100%;

        // &.checkbox{
        //     display: flex;
        //     align-items: flex-start;
        //     gap: 8px;
        //     font-size: 14px;
        //     line-height: 100%;

        //     span{
        //         transition: $transition;
        //     }
        // }
    }

    &-input{
        width: 100%;
        padding: 13px;
        margin-top: 8px;
        background: #1C1C1C;
        border: 1px solid rgba(255, 255, 255, 0.9);
        font-size: 16px;
        line-height: 1.2;
        font-weight: 400;
        border-radius: 8px;
        color: rgba(255, 255, 255, 0.9);
        transition: $transition;
        outline: none;

        &:hover,&:focus{
            border: 1px solid $accent;
        }

        &::placeholder{
        color: rgba(255, 255, 255, 0.9);
            font-size: 16px;
            line-height: 1.14;
            font-weight: 400;
        }

        &.textarea{
            resize: none;
            min-height: 100px;
            border-radius: 8px;
        }
    
        // &.checkbox{
        //     border-radius: 8px;
        //     width: 21px;
        //     height: 21px;
        //     margin: 0;

        // }
    }

    .btn{
        margin-top: 8px;
        padding: 17px 72px;
        max-width: 100%;
        font-weight: 400;
            font-size: 16px;
            line-height: 162%;
            border-radius: 8px;
    }


}