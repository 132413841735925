// ===================== Sections start ============
.hero{
    padding: 60px 0px 100px 0px;
    background-color: rgb(247, 249, 242);

    .content-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include desktop{
            flex-direction: row;

        }
    }

    .text-wrapper{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .text{
        max-width: 410px;
    }

    .image{
        display: none;
        @include tablet{
            display: block;
            width: 100%;
            max-width: 500px;
            align-self: flex-end;
        }
    }
    
}


.advantages{
        color: $white;
        padding-top: 130px;
        padding-bottom: 130px;

        background-color: $second;

        @include tablet {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    .list{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;

        @include desktop{
            flex-direction: row;
        }
        
        &__item{
            width: 100%;
            max-width: 140px;
           

            @include tablet{
                max-width: 300px;
                text-align: center;
            }
        }
    }

    p{
        font-weight: 500;
        color: #CBD5E1;
    }
}

.features{
    background-color: rgba(17, 17, 17, 1);
    color: rgba(255, 255, 255, 0.9);
    padding-top: 100px;
    padding-bottom: 100px;

    h2{
        max-width: 630px;
        @include tablet{
            margin: 0 auto;
            text-align: center;
        }

    }

    .list{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 100px;

        margin-top: 100px;

        @include tablet{

            gap: 20px;
            align-items: center;
            justify-content: center;
        }

        @include desktop {
            margin-top: 50px;
            flex-direction: row;
            gap: 110px;
        }

        &__item{
           max-width: 360px;
        }
    }

    h3{
        margin-bottom: 10px;
    }

    .text-container{
        padding: 25px 45px 10px 45px;
    }


    .image{
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

    }
}

.business{
    padding-top: 100px;
    padding-bottom: 100px;
    color: $white;
    background-color: rgba(17, 17, 17, 1);

    .list{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 100px;

        @include desktop{
            gap: 200px;
        }

        &__item{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 50px;

            @include desktop{
                flex-direction: row;
                gap: 170px;
                align-items: center;
            }

            &:nth-child(even){
                                @include desktop {
                                        flex-direction: row-reverse;
                                    }

                .classic-text {
                        margin-top: 20px;
                        margin-bottom: 40px;
                    }

               
                
                
            }

            .image{
                width: 100%;
                max-width: 330px ;
                @include tablet{
                    max-width: 600px;
                    margin: 0 auto;
                }
                @include desktop{
                    margin: 0 0 0 30px;
                }
              
            }

            p{
                color: rgba(255, 255, 255, 0.9);
            }

            .text{
                margin-top: 20px;
                margin-bottom: 10px;
                    font-weight: 600;
            }
        }
    }
    
}



.consulting{
    color: $white;
    padding: 100px 0;
    background-color: rgba(17, 17, 17, 1);
    

    .sup-title{
            font-weight: 500;
            font-size: 18px;
            line-height: 156%;
           
            color: rgba(255, 255, 255, 0.9);

            @include tablet{
                text-align: center;
                            margin-left: auto;
                                margin-right: auto;
            }
    }

    .title{
        margin-top: 20px;
        margin-bottom: 100px;
        max-width: 600px;
        @include tablet{
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .list{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 50px;

        @include desktop{
            flex-direction: row;
            align-items: stretch;
        }

        &__item{
            color: rgba(255, 255, 255, 0.9);

            display: flex;
            flex-direction: column;

            padding: 30px 20px;
            border-radius: 10px;
            background-color: rgba(247, 247, 247, 0.1);
            width: 100%;
            max-width: 500px;
            min-height: 388px;

            @include desktop{
                max-width: 400px;
            }


            .stars-wrapper{
                display: flex;
                align-items: center;
                gap: 3px;
                margin-bottom: 20px;
            }

            .author-wrapper{
                margin-top: auto;
                display: flex;
                align-items: center;
                gap: 15px;
            }

            .author-title{
                font-weight: 600;
                    font-size: 16px;
                    line-height: 162%;
                    color: rgba(255, 255, 255, 0.9);
            }
            .author-descr{
                font-size: 14px;
                line-height: 171%;
            }




        }
    }

    .link-more{
        display: none;
        @include desktop{
            display: block;
        margin-top: 50px;
        font-weight: 600;
            font-size: 16px;
            line-height: 162%;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            padding-bottom: 5px;
            text-decoration: underline;
    }
    }
}

.discover{
    color: $white;
    background-color: $second;
    
    padding-top: 100px;
    padding-bottom: 100px;

    .content-wrapper{
        width: 100%;
        max-width: 650px;
        text-align: center;
        align-items: center;
        margin: 0 auto;
    }

    .sub-title{
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .text-container{
        display: flex;
        justify-content: center;
        
        .wrapper{
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
            max-width: 224px;

            font-size: 16px;
                line-height: 162%;
            text-align: center;

            &:nth-child(1){
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 46px;
                    background-color: rgba(212, 212, 216, 0.3);
                }
            }

            @include desktop{
                max-width: 400px;
                padding-left: 50px;
                padding-right: 50px;
            }

            &-title{
                font-weight: 600;
                    font-size: 36px;
                    line-height: 128%;
                    color: #fafafa;
                    margin-bottom: 20px;
            }

            &__sub-title{
                font-weight: 500;
                    font-size: 21px;
                    line-height: 148%;
                   
                    color: #fff;
                    margin-bottom: 10px;
            }
        }
    }
}

.strides{
    padding-top: 150px;
    padding-bottom: 150px;
    color: $white;
    background-color: rgba(17, 17, 17, 1);

    .title{
        font-family: $second-font;
        font-weight: 700;
        font-size: 32px;
        letter-spacing: -0.03em;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 20px;
    }
    .sub-title{
        margin-bottom: 40px;
    }


    .content-wrapper{
        display: flex;
        flex-direction: column;
        gap: 100px;
        @include desktop{
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            gap: 90px;
        }
    }

    .image{
        width: 100%;
        max-width: 575px;
        @include tablet{
            align-self: center;
        }
        @include desktop{
            margin-top: 50px;
        }
    }


    .list{
        display: flex;
        flex-direction: column;
        gap: 15px;

        &__item{
            display: flex;
            align-items: flex-start;
            gap: 15px;
        }

    }
}

.blog{
    padding-top: 0;
    padding-bottom: 100px;

    color: $white;
    background-color: rgba(17, 17, 17, 1);

    @include desktop{
        padding-top: 100px;
    }
    .text-wrapper{
        text-align: center;
        max-width: 630px;
        margin: 0 auto 50px auto;
    }

    .title{
        font-weight: 700;
        font-size: 32px;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 20px;
    }

    .sub-title{
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
    }

    .list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        margin: 0 auto;
        @include desktop{
            flex-direction: row;
        }

        &__item{
            width: 100%;
            max-width: 635px;

                            &.second {
                                @include desktop{
                                    max-width: 525px;
                                }
                                }
            .image{
                width: 100%;
                border-radius: 10px;
                margin-bottom: 20px;

            }

            .text{
                margin-bottom: 10px;
                font-size: 14px;
                    line-height: 171%;

                    color: rgba(255, 255, 255, 0.9);
            }
            .item-title{
                
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 142%;
                    color: rgba(255, 255, 255, 0.9);
            }
            .text-container{
                max-width: 420px;
            }

        }
    }


}

.contacts{
    padding-top: 0;
    padding-bottom: 150px;
    color: $white;
        background-color: rgba(17, 17, 17, 1);

    @include desktop{
        padding-top: 100px;
    }

}












// ===================== Sections end ============